<template>
  <div>
    <b-modal id="create__person" title="Crear persona">
      <PersonCreate />
    </b-modal>
    <div class="row">
      <b-form-group class="col-md-6" label="Motivo del traslado">
        <b-form-select
          v-model="form.transport_reason"
          :options="dispatch_types"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="col-md-6" label="Tipo de transporte">
        <b-form-select
          v-model="form.transport_type"
          :options="transport_types"
          @change="resetTransportType()"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="col-md-6" label="Origen">
        <multiselect
          v-model="form.warehouse"
          track-by="id"
          label="name"
          placeholder="Seleccione un origen"
          :options="warehouses"
          :searchable="true"
          :allow-empty="false"
        >
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="Destino">
        <multiselect
          v-model="form.arrival_zone"
          track-by="id"
          label="canonical_name"
          placeholder="Seleccione ubigeo destino"
          :options="arrivals"
          :searchable="true"
          :allow-empty="false"
          @search-change="searchUbigeo"
        >
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="Dirección destino">
        <b-form-input v-model="form.arrival_address"></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="Peso bruto total (KGM)">
        <b-form-input type="number" v-model="form.peso_total"></b-form-input>
      </b-form-group>
    </div>
    <div class="row" v-if="form.transport_type === '01'">
      <b-form-group
        label="Empresa de transporte pública"
        class="col-md-6"
        label-size="sm"
      >
        <span class="label__create" @click="openModalNewPerson">[+nuevo]</span>
        <multiselect
          v-model="form.public_driver"
          :multiple="false"
          track-by="id"
          label="doc_number"
          :options="customers"
          :searchable="true"
          :allow-empty="true"
          @search-change="searchCustomer"
          placeholder="Ingrese DNI/RUC/CE/PASS"
        >
          <template slot="singleLabel" slot-scope="{ option }"
            >{{ option.doc_number }} -
            {{ formatCustomer(option) }}
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="Fecha entrega bienes">
        <b-form-input
          type="date"
          v-model="form.private_delivery_date"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="Observaciones">
        <b-form-input v-model="form.observations"></b-form-input>
      </b-form-group>
    </div>
    <div class="row" v-if="form.transport_type === '02'">
      <b-form-group class="col-md-6" label="Vehículo">
        <multiselect
          v-model="form.vehicle"
          track-by="id"
          label="canonical_name"
          placeholder="Seleccione un vehículo"
          :options="vehicles"
          :searchable="true"
          :allow-empty="false"
        >
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="Conductor">
        <multiselect
          v-model="form.driver"
          track-by="id"
          label="full_name"
          placeholder="Seleccione un conductor"
          :options="drivers"
          :searchable="true"
          :allow-empty="false"
        >
        </multiselect>
      </b-form-group>
      <b-form-group class="col-md-6" label="Fecha inicio del translado">
        <b-form-input
          type="date"
          v-model="form.public_departure_date"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="Observaciones">
        <b-form-input v-model="form.observations"></b-form-input>
      </b-form-group>
    </div>
    <div class="row">
      <b-form-group class="col-md-6">
        <b-button variant="success" @click="save()">
          <b-icon icon="save" aria-hidden="true"></b-icon>
          Guardar
        </b-button>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Utils from "../../../../core/utils/utils";
import PersonCreate from "@/view/pages/person/PersonCreate";

export default {
  name: "DespatchModalCreate",
  components: { PersonCreate },
  data() {
    return {
      arrivals: [],
      customers: [],
      drivers: [],
      form: {
        arrival_zone: null,
        arrival_address: null,
        driver: null,
        transport_reason: null,
        transport_type: null,
        vehicle: null,
        warehouse: null,
        peso_total: 1,
        private_delivery_date: null,
        public_departure_date: null,
        public_driver: null,
        observations: null,
      },
      transport_types: [
        { value: "01", text: "PÚBLICO" },
        { value: "02", text: "PRIVADO" },
      ],
      dispatch_types: [
        { value: "01", text: "VENTA" },
        {
          value: "04",
          text: "TRASLADO ENTRE ESTABLECIMIENTOS DE LA MISMA EMPRESA",
        },
      ],
      vehicles: [],
      warehouses: [],
    };
  },
  methods: {
    formatCustomer(customer) {
      let customerName = "";
      if (Utils.isNotNullOrEmpty(customer.first_name)) {
        customerName += customer.first_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name)) {
        customerName += customer.last_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name_2)) {
        customerName += customer.last_name_2;
      }
      return customerName;
    },
    getDrivers() {
      ApiService.get("core/user", "?page_size=1000000&page=1").then((res) => {
        this.drivers = res.data.results;
      });
    },
    getVehicles() {
      ApiService.get("core/vehicle", "?page_size=1000000&page=1").then(
        (res) => {
          this.vehicles = res.data.results;
        }
      );
    },
    getWarehouses() {
      ApiService.get("core/warehouse", "?page_size=1000000&page=1").then(
        (res) => {
          this.warehouses = res.data.results;
        }
      );
    },
    openModalNewPerson() {
      this.$bvModal.show("create__person");
    },
    resetTransportType() {
      this.driver = null;
      this.vehicle = null;
      this.public_driver = null;
    },
    save() {
      let form = { ...this.form };
      form.pos_key = localStorage.getItem("pos_key");
      form.invoice = parseInt(localStorage.invoice, 10);
      form.arrival_zone = form.arrival_zone.id;
      form.warehouse = form.warehouse.id;
      // PUBLIC
      if (form.transport_type === "01") {
        form.public_driver = form.public_driver.id;
      }
      // PRIVATE
      if (form.transport_type === "02") {
        form.driver = form.driver.id;
        form.vehicle = form.vehicle.id;
      }

      ApiService.post("core/dispatch/", form).then((res) => {
        console.log(res);
      });
    },
    searchCustomer(query) {
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(
          "core/person",
          `?page=1&page_size=100&doc_number=${query}`
        ).then((res) => {
          this.customers = res.data.results;
        });
      }, 750);
    },
    searchUbigeo(query) {
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(
          "core/ubigeo",
          `?page=1&page_size=10&query=${query}`
        ).then((res) => {
          this.arrivals = res.data.results;
        });
      }, 750);
    },
  },
  mounted() {
    this.getDrivers();
    this.getVehicles();
    this.getWarehouses();
    const today = new Date().toISOString().slice(0, 10);
    this.form.private_delivery_date = today;
    this.form.public_departure_date = today;
  },
  props: ["invoice"],
  watch: {},
  computed: {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.label__create {
  font-weight: bold;
  float: right;
  margin-top: -25px;
  color: #3699ff;
  cursor: pointer;
}
</style>
