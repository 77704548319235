<template>
  <div>
    <b-modal
      id="modal__create__despatch"
      hide-footer
      title="Crear guía remisión"
      size="xl"
    >
      <despatch-modal-create invoice=""></despatch-modal-create>
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div class="table-responsive">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 200px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                    <th class="p-0" style="min-width: 125px"></th>
                    <th class="p-0" style="min-width: 110px"></th>
                    <th class="p-0" style="min-width: 150px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="pl-0 py-4">
                      <div class="symbol symbol-50 symbol-light mr-1">
                        <span class="symbol-label"
                          ><img
                            src="media/misc/invoice.png"
                            alt=""
                            class="h-50 align-self-center"
                        /></span>
                      </div>
                    </td>
                    <td class="pl-0">
                      <a
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ item.serie }} - {{ item.number }}
                      </a>
                      <div>
                        <span class="font-weight-bolder">Cliente:</span
                        ><a
                          class="text-muted font-weight-bold text-hover-primary"
                        >
                          {{ item.customer }}
                        </a>
                      </div>
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        S/. {{ item.total }} </span
                      ><span class="text-muted font-weight-bold">{{
                        item.created_at
                      }}</span>
                    </td>
                    <td class="text-right">
                      <span class="text-muted font-weight-500">
                        Laravel, Metronic
                      </span>
                    </td>
                    <td class="text-right">
                      <span
                        class="label label-lg label-inline label-light-success"
                      >
                        COMPROBADO
                      </span>
                    </td>
                    <td class="text-right pr-0">
                      <a
                        class="btn btn-icon btn-light btn-sm"
                        v-if="item.delivery_status === 'PENDANT'"
                        @click="showModalCreateDespatch(item.id)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <img
                            src="media/misc/delivery.png"
                            alt=""
                            class="h-50 align-self-center"
                          />
                        </span>
                      </a>
                      <a class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <img
                            src="media/misc/xml.png"
                            alt=""
                            class="h-50 align-self-center"
                          />
                        </span>
                      </a>
                      <a class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <img
                            src="media/misc/pdf.png"
                            alt=""
                            class="h-50 align-self-center"
                          />
                        </span>
                      </a>
                      <a class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <img
                            src="media/misc/print.png"
                            alt=""
                            class="h-50 align-self-center"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DespatchModalCreate from "@/view/pages/core/despatch/DespatchModal";

export default {
  name: "SalesList",
  components: { DespatchModalCreate },
  data() {
    return {
      invoice: null,
      page: 1,
      rows: 0,
      perPage: 10,
      fields: [
        { key: "figure", label: "" },
        { key: "serie", label: "Serie" },
        { key: "number", label: "Número" },
        { key: "customer", label: "Cliente" },
        { key: "delivery_status", label: "Delivery" },
        { key: "total", label: "Total" },
        { key: "actions", label: "acciones" },
      ],
      items: [],
    };
  },
  methods: {
    getItems(page = 1) {
      let slug = `?page=${page}&page_size=${this.perPage}`;
      ApiService.get("core/invoice", slug).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
    showModalCreateDespatch(invoiceId) {
      localStorage.invoice = invoiceId;
      this.$bvModal.show("modal__create__despatch");
    },
  },
  mounted() {
    this.getItems();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Comprobantes" }]);
  },
};
</script>
